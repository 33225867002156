export default function getCountries() {
    return Object.entries({
        "AE": "United Arab Emirates",
        "AT": "Austria",
        "AU": "Australia",
        "BB": "Barbados",
        "BE": "Belgium",
        "BM": "Bermuda",
        "BQ": "Caribbean Netherlands",
        "BS": "Bahamas",
        "CA": "Canada",
        "CH": "Switzerland",
        "CN": "China",
        "CV": "Cape Verde",
        "CY": "Cyprus",
        "CZ": "Czechia",
        "DE": "Germany",
        "DK": "Denmark",
        "EA": "Ceuta & Melilla",
        "ES": "Spain",
        "FI": "Finland",
        "FJ": "Fiji",
        "FK": "Falkland Islands",
        "FR": "France",
        "GB": "United Kingdom",
        "GG": "Guernsey",
        "GI": "Gibraltar",
        "GL": "Greenland",
        "GR": "Greece",
        "HK": "Hong Kong SAR China",
        "IC": "Canary Islands",
        "IE": "Ireland",
        "IL": "Israel",
        "IM": "Isle of Man",
        "IO": "British Indian Ocean Territory",
        "IS": "Iceland",
        "IT": "Italy",
        "JE": "Jersey",
        "JP": "Japan",
        "KR": "South Korea",
        "KY": "Cayman Islands",
        "LI": "Liechtenstein",
        "LU": "Luxembourg",
        "MC": "Monaco",
        "MH": "Marshall Islands",
        "MO": "Macau SAR China",
        "MT": "Malta",
        "MU": "Mauritius",
        "MV": "Maldives",
        "MX": "Mexico",
        "NL": "Netherlands",
        "NO": "Norway",
        "NZ": "New Zealand",
        "PH": "Philippines",
        "PL": "Poland",
        "PT": "Portugal",
        "SE": "Sweden",
        "SG": "Singapore",
        "SM": "San Marino",
        "TW": "Taiwan",
        "UM": "U.S. Outlying Islands",
        "US": "United States",
        "UZ": "Uzbekistan",
        "VG": "British Virgin Islands",
        "VI": "U.S. Virgin Islands",
    })
}

export function getCountryByCode(countryCode, countries) {
    const filteredCountry = countries.filter((country) => country[0] === countryCode);
    if (filteredCountry.length > 0) {
        return filteredCountry[0][1];
    }
    return countryCode;
}
